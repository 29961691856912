.popup {
  max-width: 400px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid @bg-grey3;
  z-index: 999;
  display: none;

  &.show {
    display: block;
  }
  input {
    border: 1px solid @bg-grey2;

    &[formcontrolname="name"] {
      text-transform: capitalize;
    }
  }
  button {
    max-width: 180px;
    width: 100%;

    &[disabled] {
      opacity: 50%;
    }
  }
}



.checkmark {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: @bg-white;
  border: 1px solid @bg-grey2;
  border-radius: 4px;
  position: relative;
}

input[type="checkbox"] {
  display: none;
}

.checkmark {
  cursor: pointer;
}

input[type="checkbox"]:checked + .checkmark:before {
  content: '';
  position: absolute;
  -webkit-mask-size: auto;
  mask-size: auto;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-image: url(/assets/images/icon/check.svg);
  mask-image: url(/assets/images/icon/check.svg);
  background-color: @text-grey4;
  width: 10px;
  height: 8px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  display: none;

  &.show {
    display: block;
    z-index: 2;
  }
}
