:root {
  --bs-font-sans-serif: "Source_Sans_Pro";
}

.h2 {
  font-weight: 400;
}

.container {
  max-width: 1211px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
}

.width-to-minimal {
  width: 1px;
}

.height-to-full {
  height: 100%;
}

.border-grey {
  border: 1px solid @text-grey5;
}

.point-blue {
  ul {
    list-style: none;
  }
  ul li {
    position: relative;
    padding-left: 24px;
  }
  ul li::before {
    content: "";
    background-color: @bg-blue;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    top: 10px;
    left: 0;
    position: absolute;
  }
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.text-left {
  text-align: left;
}

img {
  max-width: 100%;
}

hr {
  margin: 0;
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.switch {
  display: inline-block;
  height: 31px;
  position: relative;
  width: 59px;
}

.switch input {
  display:none;
}

.slider {
  background-color: @bg-grey2;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: @bg-white;
  bottom: 4px;
  content: "";
  height: 23px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 23px;
}

input:checked + .slider {
  background-color: @bg-blue;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.white-space-pre {
  white-space: pre
}

@media (min-width: @screen-lg-min) {
  .white-space-pre-lg {
    white-space: pre
  }
}

@media (max-width: 768px) {
  .margin-auto-lg {
    margin-left: auto;
    margin-right: auto;
  }
  .text-center-lg {
    text-align: center;
  }
  .d-block-xs-lg {
    display: block;
  }
}

