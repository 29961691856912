.mat-tab-label-container {
  max-width: max-content;

  .mat-tab-labels {
    background-color: @bg-blue;
    border-radius: 8px;
    padding: 4px;
    color: @text-white;

    .mat-tab-label {
      padding: 0 42px;
      opacity: 1;
      height: auto;

      &-active {
        color: @text-grey4;
        background-color: @bg-white;
        font-size: @lg-size;
        border-radius: 4px;
        padding: 2px 10px;
      }
    }
  }

  .mat-ripple-element {
    display: none !important;
  }
}
