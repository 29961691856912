// This set of mixins allows you to create margin and padding classes dynamically.

// In html, you would specify <div class="mr20-xs">Content</div> to give you margin-right: 20px
// It is based on the responsive capabilities of Bootstrap. <div class="mr20-sm"> would give you
// 20 pixels of right margin on screens small and larger.

// Mixin control

// .make-margins(@breakpoint, @size, @decrement)
//        @breakpoint: To be used with Bootstrap. Must supply breakpoints: xs, sm, md or lg
//        @size: Largest size of margin you desire. Default is 50
//        @decrement: Amount @size is decremented on each loop. Default is 10

// .make-paddings uses the same parameters


// MARGIN
.make-margins(@breakpoint, @size: 100, @decrement: 2) when (@size >= 0) {
  .make-margins(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .m-@{size}-@{breakpoint} {
    margin: @size-px;
  }
  .mt-@{size}-@{breakpoint} {
    margin-top: @size-px;
  }
  .mr-@{size}-@{breakpoint} {
    margin-right: @size-px;
  }
  .mb-@{size}-@{breakpoint} {
    margin-bottom: @size-px;
  }
  .ml-@{size}-@{breakpoint} {
    margin-left: @size-px;
  }
  .my-@{size}-@{breakpoint} {
    margin-top: @size-px;
    margin-bottom: @size-px;
  }
}

.make-margins(xs);

@media (min-width: @screen-sm-min) {
  .make-margins(sm);
}

@media (min-width: @screen-md-min) {
  .make-margins(md);
}

@media (min-width: @screen-lg-min) {
  .make-margins(lg);
}


// PADDING
.make-paddings(@breakpoint, @size: 100, @decrement: 1) when (@size >= 0) {
  .make-paddings(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .p-@{size}-@{breakpoint} {
    padding: @size-px;
  }
  .pt-@{size}-@{breakpoint} {
    padding-top: @size-px;
  }
  .pr-@{size}-@{breakpoint} {
    padding-right: @size-px;
  }
  .pb-@{size}-@{breakpoint} {
    padding-bottom: @size-px;
  }
  .pl-@{size}-@{breakpoint} {
    padding-left: @size-px;
  }
  .px-@{size}-@{breakpoint} {
    padding-right: @size-px;
    padding-left: @size-px;
  }
  .py-@{size}-@{breakpoint} {
    padding-top: @size-px;
    padding-bottom: @size-px;
  }
}

.make-paddings(xs);

@media (min-width: @screen-sm-min) {
  .make-paddings(sm);
}

@media (min-width: @screen-md-min) {
  .make-paddings(md);
}

@media (min-width: @screen-lg-min) {
  .make-paddings(lg);
}


// MARGIN
.make-border-radius(@breakpoint, @size: 100, @decrement: 2) when (@size >= 0) {
  .make-border-radius(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .b-r-@{size}-@{breakpoint} {
    border-radius: @size-px;
  }
}

.make-border-radius(xs);

@media (min-width: @screen-sm-min) {
  .make-border-radius(sm);
}

@media (min-width: @screen-md-min) {
  .make-border-radius(md);
}

@media (min-width: @screen-lg-min) {
  .make-border-radius(lg);
}



// POSITION
.make-position-type(@breakpoint, @type) when not (@type = '') {
  .position-@{type}-@{breakpoint} {
    position: ~"@{type}";
  }
}

.make-position-type(xs, relative);
.make-position-type(xs, absolute);

@media (min-width: @screen-sm-min) {
  .make-position-type(sm, relative);
  .make-position-type(sm, absolute);
}

@media (min-width: @screen-md-min) {
  .make-position-type(md, relative);
  .make-position-type(md, absolute);
}

@media (min-width: @screen-lg-min) {
  .make-position-type(lg, relative);
  .make-position-type(lg, absolute);
}


// Width percent
.make-width-percent(@breakpoint, @size: 100, @decrement: 2) when (@size >= 0) {
  .make-width-percent(@breakpoint, @size - @decrement);

  @size-percent: ~"@{size}%";

  .w-percent-@{size}-@{breakpoint} {
    width: @size-percent;
  }
}

.make-width-percent(xs);

@media (min-width: @screen-sm-min) {
  .make-width-percent(sm);
}

@media (min-width: @screen-md-min) {
  .make-width-percent(md);
}

@media (min-width: @screen-lg-min) {
  .make-width-percent(lg);
}

// Width
.make-width(@breakpoint, @size: 150, @decrement: 1) when (@size >= 0) {
  .make-width(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .w-@{size}-@{breakpoint} {
    width: @size-px;
  }
}

.make-width(xs);

@media (min-width: @screen-sm-min) {
  .make-width(sm);
}

@media (min-width: @screen-md-min) {
  .make-width(md);
}

@media (min-width: @screen-lg-min) {
  .make-width(lg);
}

// max width
.make-max-width(@breakpoint, @size: 200, @decrement: 2) when (@size >= 0) {
  .make-max-width(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .mw-@{size}-@{breakpoint} {
    max-width: @size-px;
  }
}

.make-max-width(xs);

@media (min-width: @screen-sm-min) {
  .make-max-width(sm);
}

@media (min-width: @screen-md-min) {
  .make-max-width(md);
}

@media (min-width: @screen-lg-min) {
  .make-max-width(lg);
}

//Max Width percent
.make-max-width-percent(@breakpoint, @size: 130, @decrement: 2) when (@size >= 0) {
  .make-max-width-percent(@breakpoint, @size - @decrement);

  @size-percent: ~"@{size}%";

  .mw-percent-@{size}-@{breakpoint} {
    max-width: @size-percent;
  }
}

.make-max-width-percent(xs);

@media (min-width: @screen-sm-min) {
  .make-max-width-percent(sm);
}

@media (min-width: @screen-md-min) {
  .make-max-width-percent(md);
}

@media (min-width: @screen-lg-min) {
  .make-max-width-percent(lg);
}


// height 
.make-height(@breakpoint, @size: 310, @decrement: 2) when (@size >= 0) {
  .make-height(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .h-@{size}-@{breakpoint} {
    height: @size-px;
  }
}

.make-height(xs);

@media (min-width: @screen-sm-min) {
  .make-height(sm);
}

@media (min-width: @screen-md-min) {
  .make-height(md);
}

@media (min-width: @screen-lg-min) {
  .make-height(lg);
}

// Height percent
.make-height-percent(@breakpoint, @size: 100, @decrement: 2) when (@size >= 0) {
  .make-height-percent(@breakpoint, @size - @decrement);

  @size-percent: ~"@{size}%";

  .h-percent-@{size}-@{breakpoint} {
    height: @size-percent;
  }
}

.make-height-percent(xs);

@media (min-width: @screen-sm-min) {
  .make-height-percent(sm);
}

@media (min-width: @screen-md-min) {
  .make-height-percent(md);
}

@media (min-width: @screen-lg-min) {
  .make-height-percent(lg);
}

// min height
.make-min-height(@breakpoint, @size: 200, @decrement: 2) when (@size >= 0) {
  .make-min-height(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .mh-@{size}-@{breakpoint} {
    min-height: @size-px;
  }
}

.make-min-height(xs);

@media (min-width: @screen-sm-min) {
  .make-min-height(sm);
}

@media (min-width: @screen-md-min) {
  .make-min-height(md);
}

@media (min-width: @screen-lg-min) {
  .make-min-height(lg);
}


// Z-index
.make-z(@breakpoint, @size: 102, @decrement: 2) when (@size >= 0) {
  .make-z(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}";

  .z-@{size}-@{breakpoint} {
    z-index: @size-px;
  }
}

.make-z(xs);

@media (min-width: @screen-sm-min) {
  .make-z(sm);
}

@media (min-width: @screen-md-min) {
  .make-z(md);
}

@media (min-width: @screen-lg-min) {
  .make-z(lg);
}

// SIZE TEXT
.make-text-size(@breakpoint, @size: 100, @decrement: 2) when (@size >= 0) {
  .make-text-size(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .text-@{size}-@{breakpoint} {
    font-size: @size-px;

    /* guard for attribute */
    & when (@size = 40) {
      line-height: 100%;
    }
    & when (@size < 24) {
      line-height: 140%;
    }
    & when (@size >= 24) {
      line-height: 120%;
    }
  }
}

.make-text-size(xs);

@media (min-width: @screen-sm-min) {
  .make-text-size(sm);
}

@media (min-width: @screen-md-min) {
  .make-text-size(md);
}

@media (min-width: @screen-lg-min) {
  .make-text-size(lg);
}


// Bottom
.make-bottom(@breakpoint, @size: 100, @decrement: 2) when (@size >= 0) {
  .make-bottom(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .bottom-@{size}-@{breakpoint} {
    bottom: @size-px;
  }
}

.make-bottom(xs);

@media (min-width: @screen-sm-min) {
  .make-bottom(sm);
}

@media (min-width: @screen-md-min) {
  .make-bottom(md);
}

@media (min-width: @screen-lg-min) {
  .make-bottom(lg);
}

// Bottom
.make-bottom-minus(@breakpoint, @size: 100, @decrement: 2) when (@size >= 0) {
  .make-bottom-minus(@breakpoint, @size - @decrement);

  @size-px: ~"-@{size}px";

  .-bottom-@{size}-@{breakpoint} {
    bottom: @size-px;
  }
}

.make-bottom-minus(xs);

@media (min-width: @screen-sm-min) {
  .make-bottom-minus(sm);
}

@media (min-width: @screen-md-min) {
  .make-bottom-minus(md);
}

@media (min-width: @screen-lg-min) {
  .make-bottom-minus(lg);
}

// Right
.make-right(@breakpoint, @size: 100, @decrement: 2) when (@size >= 0) {
  .make-right(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .right-@{size}-@{breakpoint} {
    right: @size-px;
  }
}

.make-right(xs);

@media (min-width: @screen-sm-min) {
  .make-right(sm);
}

@media (min-width: @screen-md-min) {
  .make-right(md);
}

@media (min-width: @screen-lg-min) {
  .make-right(lg);
}


// Top
.make-top(@breakpoint, @size: 100, @decrement: 2) when (@size >= 0) {
  .make-top(@breakpoint, @size - @decrement);

  @size-px: ~"@{size}px";

  .top-@{size}-@{breakpoint} {
    top: @size-px;
  }
}

.make-top(xs);

@media (min-width: @screen-sm-min) {
  .make-top(sm);
}

@media (min-width: @screen-md-min) {
  .make-top(md);
}

@media (min-width: @screen-lg-min) {
  .make-top(lg);
}
