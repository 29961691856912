// Background color
  .bg-white {
    background-color: @bg-white;
  }
  .bg-black {
    background-color: @bg-black;
  }
  .bg-purple {
    background-color: @bg-purple;
  }
  .bg-purple2 {
    background-color: @bg-purple2;
  }
  .bg-grey {
    background-color: @bg-grey;
  }
  .bg-grey2 {
    background-color: @bg-grey2;
  }
  .bg-grey3 {
    background-color: @bg-grey3;
  }
  .bg-blue {
    background-color: @bg-blue;
  }

@media (min-width: @screen-lg-min) {
  .bg-white-lg {
    background-color: @bg-white;
  }
  .bg-black-lg {
    background-color: @bg-black;
  }
  .bg-purple-lg {
    background-color: @bg-purple;
  }
  .bg-purple2-lg {
    background-color: @bg-purple2;
  }
  .bg-grey-lg {
    background-color: @bg-grey;
  }
  .bg-grey2-lg {
    background-color: @bg-grey2;
  }
  .bg-grey3-lg {
    background-color: @bg-grey3;
  }
  .bg-blue-lg {
    background-color: @bg-blue;
  }
}


// Background color hover
  .bg-white-hover {
    &:hover {
      background-color: @bg-white;
    }
  }
  .bg-blue-hover {
    &:hover {
      background-color: @bg-blue;
    }
  }
