// border

.border-blue {
  border: 1px solid @bg-blue;
}
.border-top-blue {
  border-top: 1px solid @bg-blue;
}
.border-grey {
  border: 1px solid @bg-grey;
}
.border-grey2 {
  border: 1px solid @bg-grey2;
}
.border-grey3 {
  border: 1px solid @bg-grey3;
}
.border-white {
  border: 1px solid @bg-white;
}


// hover border
.border-blue-hover {
  &:hover {
    border: 1px solid @bg-blue;
  }
}

@media (min-width: @screen-lg-min) {
  .border-blue-lg {
    border: 1px solid @bg-blue;
  }
  .border-top-blue-lg {
    border-top: 1px solid @bg-blue;
  }
  .border-grey-lg {
    border: 1px solid @bg-grey;
  }
  .border-grey2-lg {
    border: 1px solid @bg-grey2;
  }
  .border-grey3-lg {
    border: 1px solid @bg-grey3;
  }
  .border-white-lg {
    border: 1px solid @bg-white;
  }
  // hover border
  .border-blue-hover-lg {
    &:hover {
      border: 1px solid @bg-blue;
    }
  }
}
