
@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-Black.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-BlackItalic.ttf) format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf) format("truetype");
  font-weight: 250;
  font-style: normal;
}
@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf) format("truetype");
  font-weight: 250;
  font-style: italic;
}

@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Source_Sans_Pro";
  src: local("Source_Sans_Pro"), url(../../../assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}
