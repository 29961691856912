.navbar {
  .navbar-nav {
    ul {
      display: none;

      &.active {
        display: block;
      }

      ul {
        top: 0;
        margin-left: 30px;
      }
    }
  }
}
