body {
  .slick-list {
    margin-left: -10px;
    margin-right: -10px;

    .slick-track {
      display: flex;
      height: auto;

      .slick-slide {
        height: auto;
      }
    }
  }
  .slick-prev,
  .slick-next {
    z-index: 100;
    height: auto;
    opacity: 1;
    display: table;
    width: auto;

    &:before {
      width: 35px;
      height: 35px;
      display: block;
      content: "";
    }
  }
  .slick-prev {
    left: -45px;

    &:before {
      background-image: url(/assets/images/icon/arrow-left.svg);
    }
  }
  .slick-next {
    right: -45px;

    &:before {
      background-image: url(/assets/images/icon/arrow-right.svg);
    }
  }
  .slick-dots li {
    width: 29px;
    height: 5px;
    background-color: @bg-blue;
    border-radius: 4px;
    border: 0;
    margin-left: 0;
    margin-right: 5px;
    opacity: 0.1;

    button:before {
      content: "";
      display: none;
    }
    &.slick-active {
      opacity: 1;
    }
  }
  .slick-slider.navigation-bottom {
    .slick-prev,
    .slick-next {
      bottom: 25px;
      top: inherit;
    }
    .slick-prev {
      left: 40px;
    }
    .slick-next {
      left: 82px;
      right: inherit;
    }
    .slick-dots {
      width: auto;
      bottom: 45px;
      left: 144px;
      right: inherit;
      top: inherit;
      margin: 0;
    }
  }
  .block-with-items .slick-list {
    padding: 0 40px 0 20px !important;
  }
}
