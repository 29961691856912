// Text color
  .text-white {
    color: @text-white;
  }
  .text-black {
    color: @text-black;
  }
  .text-purple {
    color: @text-purple;
  }
  .text-blue {
    color: @text-blue;
  }
  .text-blue2 {
    color: @text-blue2;
  }
  .text-grey {
    color: @text-grey;
  }
  .text-grey2 {
    color: @text-grey2;
  }
  .text-grey3 {
    color: @text-grey3;
  }
  .text-grey4 {
    color: @text-grey4;
  }
  .text-grey5 {
    color: @text-grey5;
  }
  .text-grey6 {
    color: @text-grey6;
  }
  .text-grey7 {
    color: @text-grey7;
  }
  .text-grey8 {
    color: @text-grey8;
  }
  .text-grey9 {
    color: @text-grey9;
  }

// text color hover
  .text-white-hover {
    &:hover {
      color: @text-white;
    }
  }
  .text-blue-hover {
    &:hover {
      color: @text-blue !important;
    }
  }
