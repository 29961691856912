/* You can add global styles to this file, and also import other style files */
@import "assets/styles/roboto.css";
@import "assets/styles/components/fonts";

@import "assets/styles/variables";
@import "assets/styles/global";
@import "assets/styles/mixin";

@import "assets/styles/components/background";
@import "assets/styles/components/border";
@import "assets/styles/components/text";
@import "assets/styles/components/slick";
@import "assets/styles/components/lk";
@import "assets/styles/components/popup";
@import "assets/styles/components/menu";
@import "assets/styles/bootstrap.min.css";
@import "assets/styles/material.css";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
